
import OrderCard from './OrderCard'
import OrderCardAddress from './OrderCardAddress'

export default {
    name: 'OrderPay',
    components: {
        OrderCard,
        OrderCardAddress
    },
    asyncData({api, cookies, ...context}) {},
    props: {
        currency: Object,
        middleEmit: Object,
        orderInfo: Object,
        address: Object
    },
    data() {
        return {
            rsInfo: {},
            pay: {              // 支付信息
                type: '',       // 支付类型
                channel: '',    // 支付渠道
                list: [],       // 支付列表
            },
            amt: {},
            popup: {
                visible: false,
                class: '',
                type: 1,
                title: ''
            },
            card: {}
        }
    },
    computed: {},
    watch: {
        orderInfo: {
            handler() {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            const stoPay = this.$storage.get('order/payMethod') || '';
            this.rsInfo = JSON.parse(JSON.stringify(this.orderInfo));
            this.amt = this.rsInfo.useCurrencyAmtDetail || {}
            this.pay.type = stoPay ? this.amt.currentUsePayment : ''
            this.pay.list = this.rsInfo.supportPayment || []
            this.pay.list.forEach(p => {
                if(p.paymentMethod == 'Zip') {
                    this.amt.isSupportZip == 2 && (p.isEnabled = false)
                    this.amt.isSupportZip == 3 && (p.disabled = true)
                }
                if(p.paymentMethod == this.pay.type) {
                    this.pay.channel = p.payChannel
                }
                p.disabled = !p.isEnabled
            })
            this.setStoragePay()
            this.middleEmit.fn('handlePay', this.pay)
        },
        // 支付方式
        handlePay(obj) {
            if(obj.disabled) {
                return false
            }
            if(this.pay.type == obj.paymentMethod) {
                return
            }
            if(!obj.supportCurrency.includes(this.currency.code)) {
                this.$Dialog.confirm({
                    message: this.$translate(`You order currency will be converted to USD, which is the only currency this payment methods supports.`),
                    confirmButtonText: this.$translate('OK'),
                    confirmButtonColor: '#333',
                    cancelButtonText: this.$translate('Cancel'),
                    cancelButtonColor: '#999'
                }).then(() => {
                    this.$storage.set('currency', {
                        code: 'USD',
                        symbol: '$'
                    }, true)
                    let clear = setTimeout(() => {
                        clearTimeout(clear)
                        location.reload()
                    }, 100)
                }).catch(() => {
                    this.pay.type = this.amt.currentUsePayment
                    this.handlePayFinish()
                })
                return
            }
            this.pay.type = obj.paymentMethod
            this.pay.channel = obj.payChannel
            if(obj.supportCurrency.includes(this.currency.code)) {
                this.handlePayFinish()
            }
        },
        handlePayFinish() {
            this.setStoragePay()
            this.middleEmit.fn('handlePay', this.pay)
            // this.$fnApi.debounce(() => {
            //     this.setStoragePay()
            //     // this.middleEmit.fn('getOrderInfo', {
            //     //     hasLoading: false
            //     // })
            // }, 300)
        },
        setStoragePay() {
            this.pay.type && this.$storage.set('order/payMethod', this.pay.type)
        },
        // 提示
        handlePayTip(obj) {
            this.popup.class = ''
            this.popup.origin = location.origin
            // Klarna提示
            if(obj.showPayment.includes('Klarna')) {
                if(!obj.isEnabled) {
                    this.$Dialog.alert({
                        message: `This payment methods is only available with orders total between ${this.$price(obj.info.displayMinAmount, this.currency.symbol)}~${this.$price(obj.info.displayMaxAmount, this.currency.symbol)}`,
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#333'
                    }).then(() => {});
                    return
                }
                this.popup.visible = true
                this.popup.class = 'orderConfirm-popup--klarna'
                this.popup.src = this.popup.origin + '/policy?id=1679369703058RO9ni4Zp&hideBack=false'
                return
            }
            // Affirm提示
            if(obj.showPayment.includes('Affirm')) {
                if(!obj.isEnabled) {
                    this.$Dialog.alert({
                        message: `This payment methods is only available with orders total between ${this.$price(obj.info.displayMinAmount, this.currency.symbol)}~${this.$price(obj.info.displayMaxAmount, this.currency.symbol)}`,
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#333'
                    }).then(() => {});
                    return
                }
                return
            }
            // tip提示
            if(obj.payChannel == 'Zip') {
                if(!obj.isEnabled) {
                    this.$Dialog.alert({
                        message: `${this.$translate('This payment methods is only available with orders total between')} ${this.$price(obj.info.displayMinAmount, this.currency.symbol)}~${this.$price(obj.info.displayMaxAmount, this.currency.symbol)}`,
                        confirmButtonText: this.$translate('OK'),
                        confirmButtonColor: '#333'
                    }).then(() => {});
                    return
                }
                this.popup.visible = true
                this.popup.class = 'orderConfirm-popup--zip'
                this.popup.src = this.popup.origin + '/policy?id=1679369687020Wzc0MQTq&hideBack=false'
            }
            // paypal提示
            if(obj.payChannel == 'Paypal') {
                this.$Dialog.confirm({
                    message: this.$translate('If you don’t have a paypal account. you can also pay via paypal with your credit card or bank debit card. Payment can be submitted in any currency'),
                    confirmButtonText: this.$translate('OK'),
                    showCancelButton: false,
                    confirmButtonColor: '#333',
                }).then(() => {
                }).catch(() => {});
            }
            // COD提示
            if(obj.payChannel == 'COD') {
                this.$Dialog.confirm({
                    message: `${this.$translate('Cash On Delivery service is available only when total amount after discount is between ')}${this.$price(obj.displayMinAmount, this.currency.symbol)} ${this.$translate('and')} ${this.$price(obj.displayMaxAmount, this.currency.symbol)}, ${this.$translate('and')} COD ${this.$translate('cost is not included')}`,
                    confirmButtonText: this.$translate('OK'),
                    showCancelButton: false,
                    confirmButtonColor: '#333',
                }).then(() => {
                }).catch(() => {});
            }
        },
        // 内嵌信用卡/地址
        checkEmbedCard() { // 内嵌-校验
            return new Promise((resolve, reject) => {
                const ref = this.$refs.OrderCard[0];
                const refAddress = this.$refs.OrderCardAddress[0];
                ref.checkCard().then(res => {
                    if(!res) return
                    refAddress.checkAddress().then(res => {
                        res && resolve(res)
                    })
                })
            })
        },
        cardPayEmbed(obj) { // 内嵌-信用卡提交
            const ref = this.$refs.OrderCard[0];
            const refAddress = this.$refs.OrderCardAddress[0];
            refAddress.setEmbedData(obj)
            refAddress.setBillAddress()
            ref.setEmbedSubmit(obj)
        },
        // 初始化
        initialSet() {
            this.currency = this.$storage.get('currency') || {}
            this.popup.title = `${$translate('Shop now, pay later with') }}} Klarna!`
        }
    },
}
