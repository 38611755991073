
export default {
    name: 'OrderCheckPhone',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        pay: Object,
        popup: Object,
        address: Object,
        middleEmitChild: Object
    },
    data() {
        return {
            interval: '',
            countDown: 60 * 1000,
            hasCountDown: false,
            form: {
                mobileNumber: '',
                verificationCode: ''
            }
        }
    },
    computed: {},
    watch: {
        popup: {
            handler() {
                this.handleCode()
            },
            deep: true,
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 获取订单短信验证码接口
        getCodCodeAjax() {
            this.$api.order.getVerifyCode({
                mobileNumber: this.address.phone,
            }).then(res => {
            })
        },
        setCodCodeVerifyAjax() {
            this.$api.order.setCheckVerifyCode({
                mobileNumber: this.address.phone,
                verificationCode: this.form.verificationCode
            }).then(response => {
                const res = response || {};
                if(res.code == 'success') {
                    this.popup.visible = false
                    this.middleEmitChild.fn('checkPhoneSuccess')
                }
            })
        },
        // 表单验证
        formSubmit(e) {
            this.setCodCodeVerifyAjax()
        },
        checkForm() {
            this.$refs.form.submit()
        },
        handleSubmit() {
            this.$fnApi.debounce(() => {
                this.checkForm()
            }, 300)
        },
        // 验证码
        handleCode() {
            if(!this.hasCountDown) {
                this.$fnApi.debounce(() => {
                    this.hasCountDown = true
                    this.getCodCodeAjax()
                }, 300)
            }
        },
        finishCount() {
            this.hasCountDown = false
        },
        // 弹窗关闭监听
        closeEvent() {
            this.hasCountDown = false
            this.form.verificationCode = ''
            this.countDown = 60 * 1000
            this.middleEmitChild.fn('checkPhoneClose')
        }
    },
}
