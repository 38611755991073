
import OrderCheckPhone from './OrderCheckPhone'
import OrderCheckRepeat from './OrderCheckRepeat'

export default {
    name: 'OrderSubmit',
    components: {
        OrderCheckRepeat,
        OrderCheckPhone
    },
    asyncData({api, cookies, ...context}) {},
    props: {
        amt: Object,
        currency: Object,
        middleEmit: Object,
        rsPay: Object,
        usdAmt: Object
    },
    data() {
        return {
            middleEmitChild: {},
            hasSubmitIng: false,        // 是否正在下单
            address: {},                // 下单地址
            shipping: {},               // 运输方式
            pay: {},                    // 支付方式
            coupon: {},                 // 优惠券
            note: {},                   // 留言
            giftCardProduct: {},        // 礼品卡
            placeProduct: {},           // 下单商品
            placeOrder: {},             // 创建订单回调信息
            check: {                    // 校验
                hasCheckSuccess: false, // 下单前是否校验通过
                hasCheckBasic: false,   // 基础校验是否通过
                hasCheckRepeat: false,  // 下单前重复下单校验是否通过
                hasCheckPhone: false,   // 手机校验是否通过
                hasCheckStock: false,   // 库存校验是否通过
                verificationStatus: '', // 是否需要手机校验 1 无需校验 2 需要校验 3 校验有效期内
            },
            checkPopup: {               // 手机校验弹窗
                visible: false
            },
            checkRepeatData: {              // 重复下单校验
                visible: false,
                hasCheckSuccess: false, // 重复下单是否校验成功
            },
            params: {
                orderItems: [],          // 商品参数
                checkStock: [],          // 库存校验参数
                createOrder: {},         // 创建订单参数
                createGiftOrder: {},     // 创建礼品卡订单参数
                cartIds: [],             // 购物车id
                address: {},             // 下单地址
                payOrder: {},            // 支付参数
            },
            headerOrder:{}
        }
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {},
    methods: {
        // 获取key数据
        getKeyData() {
            const key = ['address', 'shipping', 'pay', 'coupon', 'note', 'placeProduct', 'params', 'query', 'wallet', 'giftCardProduct'];
            key.forEach(p => {
                this[p] = this.middleEmit.fn('getKeyData', p)
            })
        },
        // 获取参数--------------------------------
        getParams() {
            this.setZeroOrder()
            this.getProductParams()
            this.setCreateAddressParams()
            this.setCreateOrderParams()
            this.setCreateGiftCardOrderParams()
        },
        getProductParams() { // 获取参数-商品参数
            const list = this.placeProduct.list || [];
            let checkStockArr = [];
            let orderItems = [];
            let cartIds = [];
            list.forEach(p => {
                let basicObj = {
                    spuId: p.spuId,
                    skuId: p.skuId,
                    quantity: p.quantity,
                    id: p.id
                };
                // id
                cartIds.push(p.id)
                // 定制
                let cusObj = {};
                if(p.customizeId) {
                    cusObj.customizeId = p.customizeId
                    cusObj.customizeTotalFee = p.customizeTotalFee || 0
                    cusObj.customizeInfo = p.customizeParams
                }
                // 库存校验商品
                checkStockArr.push(basicObj)
                // 创建订单商品
                let orderItemObj = {
                    goodsType: p.goodsType,
                    pitchOnNewcomerGoods: p.pitchOnNewcomerGoods || false,
                    customizeType: p.customizeType,
                    ...basicObj,
                    ...cusObj
                };
                if(p.vipQuantity) { // vip服务
                    orderItemObj.vipQuantity = p.vipQuantity
                }
                orderItems.push(orderItemObj)
            })
            this.params.checkStock = checkStockArr
            this.params.orderItems = orderItems
            this.params.cartIds = cartIds
        },
        setCreateAddressParams() { // 获取参数-下单地址
            this.params.address = {
                country: this.address.englishName,
                firstName: this.address.firstName,
                lastName: this.address.lastName,
                phone: this.address.phone,
                email: this.address.email,
                countryCode: this.address.country,
                addressLine1: this.address.addressLine1,
                addressLine2: this.address.addressLine2,
                postCode: this.address.postCode,
                state: this.address.state,
                city: this.address.city,
                vatNumber: this.address.vatNumber || ''
            }
            if(this.address.middleName) {
                this.params.address.middleName = this.address.middleName
            }
            if(this.address.street) {
                this.params.address.street = this.address.street
            }
        },
        setCreateOrderParams() { // 获取参数-下单参数
            let createOrder = {
                shippingMethod: this.shipping.method,
                shippingFee: this.amt.shippingFee / 100,
                paymentMethod: this.pay.type,
                orderFrom: this.query.from,
                orderItems: this.params.orderItems,
                addressParam: this.params.address,
                billingAddressParam: this.params.address,
                mobileNumber: this.address.phone,
                cartIds: this.params.cartIds
            }
            // 留言
            if(this.note.remarks) {
                createOrder.remarks = this.note.remarks
            }
            // 优惠券
            if(this.coupon?.info?.code) {
                createOrder = {...createOrder, ...this.coupon.params}
            }
            // 手机校验状态
            if(this.check.verificationStatus) {
                createOrder.verificationStatus = this.check.verificationStatus
            }
            // 钱包
            createOrder.useWalletAmt = (this.wallet.useWalletAmt || 0) * 100
            // 礼品卡
            createOrder.useGiftCardAmt = this.amt.giftCardAmount || 0
            // 弃单
            if(this.query.orderId) {
                createOrder.mainOrderId = this.query.orderId
                createOrder.orderGiveUpId = this.query.orderGiveUpId
            }
            this.params.createOrder = createOrder
        },
        setCreateGiftCardOrderParams() { // 获取参数-礼品卡订单
            const params = this.giftCardProduct.params || {}
            const giftCardForm = params.form || {}

            this.params.createGiftOrder = {
                paymentMethod: this.pay.type,
                billingAddressParam: this.params.address,
                buyerEmail: giftCardForm.buyerEmail,
                nickName: giftCardForm.nickName,
                remarks: giftCardForm.remarks,
                giftCardId: params.cardId,
                amountId: params.amountId
            }
        },
        // 获取loading加载状态
        getLoadingData() {
            const data = this.$loadingOn('getData') || {};
            this.hasSubmitIng = data.visible
        },
        // 下单--------------------------------------
        handleSubmit() {
            this.getKeyData()
            this.getParams()
            if(this.query.from == 'giftCard') {
                // 礼品卡
                this.handleGiftCardSubmit()
                return
            }
            // 0元订单
            this.setZeroOrder()
            // 获取loading加载状态
            this.getLoadingData()
            // 下单前基础校验
            this.checkSubmit().then(() => {
                if(this.hasSubmitIng || !this.check.hasCheckBasic) return
                this.$loading({
                    type: 2,
                    text: this.$translate('Processing')
                })
                // 下单前其他校验
                this.$fnApi.debounce(() => {
                    this.hasSubmitIng = true
                    // 下单前重复下单校验
                    this.$api.common.getCountryList().then(response => {
                        const address = this.$storage.get('order/placeAddress') || {};
                        this.headerOrder = response?.result?.find(res=>{
                            return address.englishName==res.englishName
                        })
                        this.checkRepeat().then(res => {
                            // 下单前手机校验
                            this.checkPhone().then(res => {
                                // 下单前库存校验
                                this.checkStock().then(res => {
                                    this.setCreateOrder()
                                    // 结算埋点
                                    this.setBuried()
                                    // 结算日志
                                    this.settleLog()
                                })
                            })
                        })
                    })
                }, 10)
            })
        },
        createOrderAjax() { // 下单-创建订单接口
            return new Promise((resolve, reject) => {
                this.$api.order.setCreateOrder({
                    data: JSON.stringify(this.params.createOrder)
                },false,{
                    countryCode:this.headerOrder?.countryCode,
                    useCurrencyCode:this.headerOrder?.currencyCode
                }).then(response => {
                    const res = response || {};
                    resolve(res)
                }).catch(() => {
                    this.hasSubmitIng = false
                })
            })
        },
        setCreateOrder() { // 下单-创建订单
            this.check.hasCheckSuccess = this.check.hasCheckBasic && this.check.hasCheckRepeat && this.check.hasCheckPhone && this.check.hasCheckStock
            if(!this.check.hasCheckSuccess) {
                this.hasSubmitIng = false
                return
            }
            this.createOrderAjax().then(res => {
                if(res.code !== 'success') {
                    this.hasSubmitIng = false
                    return
                }
                if(res.error_code == '1004') {
                    this.hasSubmitIng = false
                    // 货币不支持校验
                    this.checkCurrency()
                    return
                }
                this.placeOrder = res?.result?.order || {}
                // 创建订单后处理
                this.setCreateEnd()
                // 支付
                this.setPay()
            })
        },
        createGiftCardOrderAjax() { // 下单-创建订单接口-礼品卡
            return new Promise((resolve, reject) => {
                this.$api.order.setCreateGiftCardOrder({
                    data: JSON.stringify(this.params.createGiftOrder)
                }).then(response => {
                    const res = response || {};
                    if(response.code == 'success') {
                        resolve(res)
                    } else {
                        this.hasSubmitIng = false
                        this.$loading(false)
                    }
                }).catch(() => {
                    this.hasSubmitIng = false
                })
            })
        },
        handleGiftCardSubmit() { // 下单-礼品卡创建订单
            if(!this.pay.type) {
                this.$Toast(this.$translate('Please select a payment method'))
                this.$VueScrollTo.scrollTo('.OrderPay', '', {
                    offset: -67
                })
                return
            }
            if(this.hasSubmitIng) return
            this.checkCardPay().then(() => {
                this.hasSubmitIng = true
                this.$loading({
                    type: 2,
                    text: this.$translate('Processing')
                })
                this.createGiftCardOrderAjax().then(res => {
                    this.placeOrder = res?.result?.order || {}
                    // 创建订单后处理
                    this.setCreateEnd()
                    // 支付
                    this.setPay()
                })
            })
        },
        // 创建订单后处理
        setCreateEnd() {
            let redirectUrl = window.location.href
            if(redirectUrl.includes('?')) {
                redirectUrl = `${redirectUrl}`
            }
            this.placeOrder.percentAmount = this.coupon?.info?.percentAmount || 0
            this.placeOrder.fee2 = this.placeOrder.shippingFee
            this.placeOrder.email = this.address.email
            // 支付数据
            this.params.payOrder = {
                amount: this.placeOrder.amtCent,
                articleAmt: this.placeOrder.amtCent - this.placeOrder.shippingFee,
                shipping: this.placeOrder.shippingFee,
                shippingDiscount: 0,
                currency: 'USD',
                orderSeq: this.placeOrder.originOrderId,
                redirectUrl: redirectUrl
            }
            if(this.pay.channel == 'Payssion') {
                this.params.payOrder.paymentMethod = this.pay.type
            }
        },
        // 支付方式----------------------------------
        setPay() {
            // 0元下单
            if(!this.placeOrder.amtCent || !this.amt.totalAmount) {
                this.freePay()
                return
            }
            // payssion支付
            if(this.pay.channel == 'Payssion') {
                this.paySSionPay()
                return
            }
            // Airwallex 空中云汇支付
            if(this.pay.channel == 'Airwallex') {
                this.awPay()
                return
            }
            // zip 分期支付
            if(this.pay.type == 'Zip') {
                this.zipPay()
                return
            }
            // klana分期支付
            if(this.pay.channel == 'Stripe_Link') {
                this.klanaPay()
                return
            }
            // Paypal 支付
            if(['Paypal', 'PayPal'].includes(this.pay.type)) {
                this.paypalPay()
                return
            }
            // COD 支付
            if(this.pay.type == 'COD') {
                this.codPay()
                return
            }
            // 信用卡支付
            this.cardPay()
        },
        setZeroOrder() { // 支付-0元支付
            if(!this.amt.totalAmount) {
                this.pay.type = 'Y-Pay'
                this.pay.channel = 'Y-Pay'
            }
        },
        freePay() { // 支付-0元支付
            this.$loading(false)
            this.hasSubmitIng = false
            this.$router.push({
                name: 'PaySuccess',
                query: {
                    order_id: this.placeOrder.originOrderId
                }
            })
        },
        paySSionPay() { // 支付-payssion 支付
            this.$api.order.setPaySSionPay(this.params.payOrder).then(response => {
                const res = response?.result || {};
                this.hasSubmitIng = false
                this.$loading(false)
                window.location.href = res.linkHref || res.redirect_url
            }).catch(err => {
                this.$loading(false)
                this.hasSubmitIng = false
            })
        },
        awPay() { // 支付-Airwallex 空中云汇支付
            this.$api.order.setAWPay({
                orderSeq: this.placeOrder.originOrderId
            }).then(response => {
                const res = response?.result || {};
                let redirectUrl = `${window.location.origin}/paysuccess?order_id=${this.placeOrder.originOrderId}`
                this.hasSubmitIng = false
                this.$loading(false)
                Airwallex.redirectToCheckout({
                    env: awEnv,
                    mode: 'payment',
                    intent_id: res.id,
                    client_secret: res.client_secret,
                    currency: res.currency,
                    successUrl: redirectUrl,
                    failUrl: redirectUrl,
                    cancelUrl: redirectUrl,
                })
            }).catch(err => {
                this.$loading(false)
                this.hasSubmitIng = false
            })
        },
        zipPay() { // 支付-zip 分期支付
            let redirectUrl = window.location.href
            this.$api.order.setZipPay({
                orderSeq: this.placeOrder.originOrderId,
                description: this.note.remarks,
                cancelUrl: redirectUrl,
                redirectUrl: redirectUrl,
            }).then(response => {
                const res = response?.result || {};
                this.hasSubmitIng = false
                this.$loading(false)
                res.linkHref && (window.location.href = res.linkHref)
            }).catch(err => {
                this.hasSubmitIng = false
                this.$loading(false)
            })
        },
        klanaPay() { // 支付-klana支付
            let redirectUrl = `${window.location.origin}/paysuccess?order_id=${this.placeOrder.originOrderId}`
            this.$api.order.setKlaNaPay({
                orderId: this.placeOrder.id,
                successUrl: redirectUrl,
                cancelUrl: location.href
            }).then(response => {
                const res = response?.result || response?.data;
                this.hasSubmitIng = false
                this.$loading(false)
                res.stripePaymentPage && (window.location.href = res.stripePaymentPage)
            }).catch(err => {
                this.hasSubmitIng = false
                this.$loading(false)
            })
        },
        paypalPay() { // 支付-Paypal 支付
            this.$api.order.setPayPalPay(this.params.payOrder).then(response => {
                const res = response?.result || {};
                this.hasSubmitIng = false
                this.$loading(false)
                res.linkHref && (window.location.href = res.linkHref);
            }).catch(err => {
                this.hasSubmitIng = false
                this.$loading(false)
            })
        },
        codPay() { // 支付-COD 支付
            this.hasSubmitIng = false
            this.$loading(false)
            this.$router.push({
                name: 'PaySuccess',
                query: {
                    order_id: this.placeOrder.originOrderId
                }
            })
        },
        cardPay() { // 支付-信用卡支付
            this.cardPayEmbed()
            // this.hasSubmitIng = false
            // this.$storage.set('order/billAddress', {
            //     ...this.address,
            //     rsSource: location.href
            // })
            // this.$router.push({
            //     name: 'CardPay',
            //     query: {
            //         order_id: this.placeOrder.id,
            //         order_seq: this.placeOrder.originOrderId
            //     }
            // })
        },
        cardPayEmbed() { // 支付-信用卡支付内嵌模式
            this.middleEmit.fn('cardPayEmbed', this.placeOrder)
        },
        // 校验--------------------------------------------
        setCheckRepeatAjax() { // 校验-获取是否重复下单
            return new Promise((resolve, reject) => {
                this.$api.order.checkRepeatCreateOrder({
                    data: JSON.stringify(this.params.createOrder)
                },{
                    countryCode:this.headerOrder?.countryCode,
                    useCurrencyCode:this.headerOrder?.currencyCode
                }).then(response => {
                    const res = response.result || {};
                    resolve(res)
                })
            })
        },
        getCheckPhoneAjax() { // 校验-获取是否需要手机校验
            return new Promise((resolve, reject) => {
                this.$api.order.getVerifyStatus({
                    mobileNumber: this.address.phone||'',
                    paymentMethod: this.pay.type,
                },{
                    countryCode:this.headerOrder?.countryCode,
                    useCurrencyCode:this.headerOrder?.currencyCode
                }).then(response => {
                    resolve(response)
                })
            })
        },
        getCheckStockAjax() {
            return new Promise((resolve, reject) => {
                this.$api.order.setCheckStockOrder({
                    data: JSON.stringify(this.params.checkStock)
                }).then(response => {
                    const res = response;
                    this.check.hasCheckStock = false
                    if(res.code == 'success') {
                        this.check.hasCheckStock = true
                    }
                    resolve(res)
                }).catch(err => {})
            })
        },
        checkSubmit() { // 校验-下单前基础校验
            return new Promise((resolve, reject) => {
                this.check.hasCheckBasic = false
                if(!this.address.country) {
                    this.$router.push({
                        name: 'Address',
                        query: {
                            key: 'order/placeAddress'
                        }
                    })
                    return
                }
                if(!this.placeProduct.quantity) {
                    this.$Toast(this.$translate('Please select the item to be ordered'))
                    return
                }
                if(!this.shipping.method) {
                    this.$Toast(this.$translate('Please select a shipping method'))
                    return
                }
                if(!this.pay.type) {
                    this.$Toast(this.$translate('Please select a payment method'))
                    this.$VueScrollTo.scrollTo('.OrderPay', '', {
                        offset: -67
                    })
                    return
                }
                this.check.hasCheckBasic = true
                this.checkCardPay().then(() => {
                    resolve(true)
                })
            })
        },
        checkCardPay() { // 校验-下单前信用卡校验
            return new Promise((resolve, reject) => {
                if(['Stripe'].includes(this.pay.type)) {
                    const ref = this.middleEmit.fn('getRef', 'OrderPay');
                    ref.checkEmbedCard().then(res => {
                        resolve(res)
                    })
                }else {
                    resolve(true)
                }
            })
        },
        checkRepeat() { // 校验-下单前重复校验
            return new Promise((resolve, reject) => {
                if(this.check.hasCheckRepeat) {
                    this.hasSubmitIng = false
                    resolve(true)
                    return
                }
                this.setCheckRepeatAjax().then(res => {
                    this.check.hasCheckRepeat = !res.repeat
                    if(this.check.hasCheckRepeat) {
                        resolve(true)
                    } else {
                        this.$loading(false)
                        this.hasSubmitIng = false
                        this.checkRepeatData.visible = true
                        this.checkRepeatData.orderId = res.orderId
                        this.checkRepeatData.originOrderId = res.originOrderId
                        this.checkRepeatData.paymentTime = res.paymentTime
                    }
                }).catch(err => {
                    this.hasSubmitIng = false
                })
            })
        },
        checkRepeatSuccess() { // 校验-重复校验是否成功
            this.check.hasCheckRepeat = true
            this.hasSubmitIng = false
            this.handleSubmit()
        },
        checkRepeatClose() { // 校验-重复校验弹窗关闭
            this.hasSubmitIng = false
        },
        checkPhone() { // 校验-下单前手机校验
            return new Promise((resolve, reject) => {
                if(this.check.hasCheckPhone && this.check.verificationStatus) {
                    this.hasSubmitIng = false
                    resolve()
                    return
                }
                this.getCheckPhoneAjax().then(response => {
                    const res = response?.result;
                    if(response.code == 'success') {
                        this.check.hasCheckPhone = false
                        this.check.verificationStatus = res
                        if(this.check.verificationStatus == 2) {
                            this.checkPopup.visible = true
                            this.hasSubmitIng = false
                        } else {
                            this.check.hasCheckPhone = true
                            resolve()
                        }
                        return
                    }
                    this.hasSubmitIng = false
                }).catch(err => {
                    this.hasSubmitIng = false
                })
            })
        },
        checkPhoneSuccess() { // 校验-手机校验是否成功
            this.check.hasCheckPhone = true
            this.handleSubmit()
        },
        checkPhoneClose() {
            this.hasSubmitIng = false
        },
        checkStock() { // 校验-下单前库存校验
            return new Promise((resolve, reject) => {
                this.getCheckStockAjax().then(res => {
                    resolve(res)
                }).catch(err => {
                    this.hasSubmitIng = false
                })
            })
        },
        checkCurrency() { // 校验-货币不支持校验
            this.$Dialog.confirm({
                message: this.$translate(`You order currency will be converted to USD, which is the only currency this payment methods supports`),
                confirmButtonText: this.$translate('OK'),
                confirmButtonColor: '#333',
                cancelButtonText: this.$translate('Cancel'),
                cancelButtonColor: '#999'
            }).then(() => {
                const defaultCurrency = {
                    code: 'USD',
                    symbol: '$',
                    id: '783db15d14454d9098b8d922aec81f73'
                };
                this.$storage.set('currency', defaultCurrency, true)
                this.$fnApi.debounce(() => {
                    location.reload()
                }, 10)
            }).catch(() => {})
        },
        // 结算埋点
        setSymbolReplace(val) {
            try {
                return val && val.includes(',') ? val.replaceAll('.', '').replaceAll(',', '.').replace(/[^\d.-]/g, '') : val
            } catch {
                return val
            }
        },
        setBuried() {
            const phone = this.address.phone && `${this.address.phone.split('-').join('')}` || '';
            let tiktokItems = [];
            let gtagId = [];
            let gtagItem = [];
            this.placeProduct.list.forEach(p => {
                // tiktok
                tiktokItems.push({
                    content_type: 'product',
                    content_id: p.productCode,
                    content_name: p.productName,
                    quantity: p.quantity,
                    price: p.displaySkuPrice,
                    value:  this.setSymbolReplace(p.displaySkuPrice),
                    currency: this.currency.code
                })
                // google
                gtagId.push({
                    item_id: p.skuId
                })
                gtagItem.push({
                    id: p.productCode,
                    name: p.productName,
                    quantity: p.quantity,
                    price: this.setSymbolReplace(p.displaySkuPrice),
                })
            })
            // gtag user info
            this.$fnApi.buried.gtag({
                type: 'user_data',
                params: {
                    "email": this.address.email,
                    "phone_number": phone
                }
            })
            this.$fnApi.buried.gtag({
                type: 'conversion_checkout',
                params: {
                    'value': this.setSymbolReplace(this.amt.displayTotalAmount),
                    'currency': this.currency.code,
                }
            })
            // gtag 支付信息
            this.$fnApi.buried.gtag({
                type: 'add_payment_info',
                params: {
                    currency: this.currency.code,
                    value: this.setSymbolReplace(this.amt.displayTotalAmount),
                    items: gtagId
                }
            })
            // gtag 邮费信息
            this.$fnApi.buried.gtag({
                type: 'add_shipping_info',
                params: {
                    currency: this.currency.code,
                    value: this.setSymbolReplace(this.amt.displayShippingFee),
                    items: gtagId
                }
            })
            // gtag 开始结账
            // this.$fnApi.buried.gtag({
            //     type: 'begin_checkout',
            //     params: {
            //         items: gtagItem
            //     }
            // })
            // tiktok
            this.$fnApi.buried.tiktok({
                type: 'AddPaymentInfo',
                params: {
                    content_type: 'product',
                    contents: tiktokItems,
                    currency: this.currency.code,
                    value: this.setSymbolReplace(this.amt.displayTotalAmount)
                }
            })
            // fb
            this.$fnApi.buried.fbq({
                type: 'AddPaymentInfo',
                params: {
                    currency: 'USD',
                    value: this.setSymbolReplace((this.usdAmt.totalAmount/100).toFixed(2))
                }
            }, this)
        },
        // 结算日志
        settleLog() {
            let spuItems = [];
            this.placeProduct.list.forEach(p => {
                spuItems.push({
                    spuId: p.spuId,
                    skuId: p.skuId,
                    quantity: p.quantity,
                    customizeType: p.customizeType || 1,
                    customizeInfo: p.customizeParams || []
                })
            })
            const params = {
                traceType: 2,
                settleSource: 'cart',
                spuItems
            };
            this.$api.product.setAddBagLog(params).then(response => {})
        },
        // 初始化
        initialSet() {
            this.middleEmitChild.fn = (fn, options) => {
                return this[fn] && this[fn](options)
            }
        }
    },
}
