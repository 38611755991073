
export default {
    name: 'OrderGiftCardAmt',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        currency: Object,
        middleEmit: Object,
        amt: Object,
    },
    data() {
        return {
            rsAmt: {},
            giftCard: {
                disabled: false,
                useGiftCardAmt: '',
                displayGiftCardAmount: '0.00',
                btnDisabled: false,
                initialUseGiftCardAmt: ''
            },
            redeem: {
                visible: false,
                giftCardCode: ''
            },
            popup: {
                visible: false
            }
        }
    },
    computed: {},
    watch: {
        amt: {
            handler(n, o) {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 数据设置
        setData() {
            this.rsAmt = JSON.parse(JSON.stringify(this.amt))
            this.giftCard.disabled = !this.rsAmt.maxUseGiftCardAmt
            this.giftCard.giftCardAmount = this.rsAmt.giftCardAmount // 用户输入的礼品卡金额
            this.giftCard.displayGiftCardAmount = this.rsAmt.displayGiftCardAmount // 用户使用的礼品金额-展示
            this.giftCard.useGiftCardAmt = this.rsAmt.giftCardRevealAmt ? this.rsAmt.displayGiftCardRevealAmt : '' // 反显在输入框内的金额
            // this.giftCard.btnDisabled = this.giftCard.disabled || this.giftCard.useGiftCardAmt === ''
            this.giftCard.btnDisabled = !this.rsAmt.maxUseGiftCardAmt
            this.giftCard.maxUseGiftCardAmt = this.rsAmt.maxUseGiftCardAmt // 可用最大礼品金额
            this.giftCard.displayMaxUseGiftCardAmt = this.rsAmt.displayMaxUseGiftCardAmt // 可用最大礼品金额-展示
            this.giftCard.userGiftCardTotalAmt = this.rsAmt.userGiftCardTotalAmt // 用户总礼品金额
            this.giftCard.displayUserGiftCardTotalAmt = this.rsAmt.displayUserGiftCardTotalAmt // 用户总礼品金额-展示
            this.giftCard.initialUseGiftCardAmt = this.giftCard.useGiftCardAmt // 初始化礼品金额
            this.middleEmit.fn('changeGiftCardAmtData', this.giftCard)
        },
        // 礼品卡使用
        handleAll() {
            if(this.rsAmt.maxUseGiftCardAmt) {
                this.giftCard.initialUseGiftCardAmt = this.rsAmt.displayMaxUseGiftCardAmt
            }
        },
        handleSet() {
            this.popup.visible = true
        },
        handleApply() {
            this.popup.visible = false
            this.giftCard.useGiftCardAmt = this.giftCard.initialUseGiftCardAmt
            this.middleEmit.fn('changeGiftCardData', this.giftCard)
            this.middleEmit.fn('getOrderInfo')
            this.$Toast(this.$translate('Applied successfully'))
        },
        // 兑换
        setGiftCardExchangeAjax() { // 礼品卡兑换接口
            this.$api.giftCard.setGiftCardExchange({
                giftCardCode: this.redeem.giftCardCode,
                exchangeType: 1
            }).then(response => {
                if(response.code == 'success') {
                    this.redeem.visible = false
                    this.middleEmit.fn('getOrderInfo')
                }
            })
        },
        handleRedeem() {
            this.redeem.visible = true
        },
        handleClaim() {
            this.setGiftCardExchangeAjax()
        }
    },
}
