
export default {
    name: 'couponChoose',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        autoCoupon: Object
    },
    data() {
        return {
            hasLogin: false,
            currency: {},               // 货币
            tab: {                      // 导航
                active: 1,
            },
            coupon: {                   // 优惠券
                length: 0,
                info: {},               // 缓存的优惠券
                list: [],               // 优惠券列表
                canList: [],            // 可使用优惠券
                availableList: [],      // 失效优惠券
            },
            placeProductInfo: {},       // 下单商品
            params: {                   // 参数
                couponId: '',           // 优惠券id
                orderItems: [],
                spuIds: []
            },
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.updateCoupon()
    },
    methods: {
        updateCoupon() {
            this.initialSet()
            this.setParamsData()
            this.hasLogin && this.getCouponAjax()
            const autoCouponInfo = this.autoCoupon?.info || {};
            if(!this.hasLogin) {
                this.coupon.canList = autoCouponInfo.couponId ? [autoCouponInfo] : []
                this.setCouponData()
            }
        },
        // 获取优惠券
        getCouponAjax() {
            this.$api.coupon.getChooseCoupon({
                orderItems: this.params.orderItems,
                spuIds: this.params.spuIds,
            }, false).then(response => {
                const res = response.result || {};
                this.coupon.canList = res.canUse || []
                this.coupon.availableList = res.invalid || []
                this.coupon.length = this.coupon.canList.length
                this.setCouponData()
            })
        },
        // 设置参数
        setParamsData() {
            this.placeProductInfo.forEach(item => {
                this.params.orderItems.push({
                    quantity: item.quantity,
                    spuId: item.spuId,
                    skuId: item.skuId,
                    goodsType: item.goodsType,
                })
                this.params.spuIds.push(item.spuId);
            })
        },
        // 设置优惠券数据
        setCouponData() {
            if(this.tab.active == 1) {
                this.coupon.list = this.coupon.canList
            } else {
                this.coupon.list = this.coupon.availableList
            }
            const freeCouponTip = [`FREE SHIPPING`, `STANDARD SHIPPING`, `EXPRESS SHIPPING`];
            this.coupon.list.forEach(item => {
                item.freeShippingType = 1
                item.threshold = ''
                item.maxThreshold = ''
                let suitTitle = [];
                let suitDesc = [];
                if(item.suitType == 1 || !item.suitSteps) {
                    item.suitSteps = []
                }
                // 阶梯
                item.suitSteps.forEach(c => {
                    if(item.type == 1) {
                        suitTitle.push(`${c.displayDiscountValue}%`)
                    } else {
                        suitTitle.push(`${this.$price(c.displayDiscountValue)}`)
                    }
                    if(item.suitType == 3) {
                        suitDesc.push(`${c.displayCondition}`)
                    } else {
                        suitDesc.push(`${this.$price(c.displayCondition)}`)
                    }
                })
                item.rsPercent = suitTitle.join('/')
                item.rsSuitDesc = suitDesc.join('/')
                // 非阶梯
                if(item.suitType == 1) {
                    if(item.type == 1) {
                        item.rsPercent = `${item.percent}% ${this.$translate('OFF')}`
                    } else {
                        item.rsPercent = `${this.$price(item.percent / 100)}`
                    }
                }
                if(item.suitType == 1) {
                    item.rsSuit = `${this.$translate('For all products')}`
                } else if(item.suitType == 2) {
                    item.rsSuit = `${this.$translate('For orders over')} ${item.rsSuitDesc}`
                } else {
                    item.rsSuit = `${this.$translate('For orders over')} ${item.rsSuitDesc} ${this.$translate('products')}`
                }
                item.rsTime = `*${this.$dayjs(item.gmtStart).format('YYYY-MM-DD')}-${this.$dayjs(item.gmtExpired).format('YYYY-MM-DD')}`
                if(item.displayDeduction) {
                    item.maxThreshold = `${this.$translate('For all products')} - ${this.$price(item.displayDeduction)}${this.$translate('max')}`
                }
                // 免邮提示
                item.shippingTip = ''
                if(item.type == 3 && item.freeShippingType) {
                    item.shippingTip = `${this.$translate('Shipping Only For')}: ${this.$translate(freeCouponTip[item.freeShippingType])}`
                }
            })
            this.$emit('getCouponData', this.coupon)
        },
        // tab change
        changeTab() {
            this.setCouponData()
        },
        // 优惠券选择
        handleCoupon(obj) {
            if(this.tab.active == 1) {
                this.params.couponId = obj.couponId
                this.$storage.set('order/coupon', obj)
                this.$emit('handleCoupon', obj)
            }
        },
        removeCoupon() {
            this.params.couponId = ''
        },
        // 提示点击
        handleTip() {
            this.$Dialog.confirm({
                message: `${this.$translate('Only one coupon can be used per order')}.`,
                confirmButtonText: this.$translate('OK'),
                confirmButtonColor: '#333',
                showCancelButton: false
            }).then(() => {
            }).catch(() => {})
        },
        // 返回
        handleBack() {
            this.$router.back()
        },
        // 初始化
        initialSet() {
            this.currency = this.$storage.get('currency') || {}
            this.placeProductInfo = this.$storage.get('order/placeProduct') || []
            this.coupon.info = this.$storage.get('order/coupon') || {}
            this.params.couponId = this.coupon.info.couponId
            this.hasLogin = this.$login()
        }
    }
}
