
export default {
    name: 'OrderAddress',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object,
        query: Object,
    },
    data() {
        return {
            hasLogin: false,
            address: {},
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        handleAddress() {
            this.hasLogin = this.$login()
            let query = {
                key: 'order/placeAddress'
            };
            let url = 'Address';
            if(this.query.orderId) {
                query.orderId = this.query.orderId
            }
            if(this.address.country && this.hasLogin) {
                url = 'AddressList'
            }
            this.$router.push({
                name: url,
                query
            })
            this.$storage.set('page/refresh', true)
        },
        // 初始化
        initialSet() {
            this.address = this.$storage.get('order/placeAddress') || {}
            this.middleEmit.fn('setAddress', this.address)
        }
    },
}
