
export default {
    name: 'OrderGiftCardProduct',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        card: Object
    },
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
}
