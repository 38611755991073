
export default {
    name: 'OrderHead',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        query: Object,
        middleEmit: Object
    },
    data() {
        return {
            referrer: ''
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        handleEChat() {
            if(ECHAT) {
                ECHAT.customMiniChat()
            }
        },
        handleBack() {
            this.middleEmit.fn('handleBack')
        }
    },
}
