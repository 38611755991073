
export default {
    name: 'OrderSafe',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            safe: {
                list: []
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        // 初始化
        initialSet() {
            this.safe.list = [{
                title: this.$translate('Safe Payments'),
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1691482502949.png'
            }, {}, {
                title: this.$translate('Secure Logistics'),
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1691482526503.png'
            }, {}, {
                title: this.$translate('Customer Service'),
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1691482532998.png'
            }, {}, {
                title: this.$translate('Privacy Protection'),
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1691482538246.png'
            }]
        }
    },
}
