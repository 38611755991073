
import CardAddress from '../../address/index'
export default {
    name: 'OrderCardAddress',
    components: {
        CardAddress
    },
    asyncData({api, cookies, ...context}) {},
    props: {
        address: Object
    },
    data() {
        return {
            clear: '',
            checked: true,
            hasAddress: false,
            query: {}
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.query = this.$route.query
    },
    methods: {
        // 地址change
        changeCheckbox() {
            this.$storage.set('order/billAddress', {
                ...this.address,
                rsSource: location.href
            })
            this.hasAddress = !this.checked
            if(this.hasAddress) {
                this.clear && clearTimeout(this.clear)
                this.clear = setTimeout(() => {
                    const ref = this.$refs.CardAddress;
                    ref.setEmbedInitial({
                        query: {
                            key: 'order/billAddress'
                        }
                    })
                }, 10)
            }
        },
        // 地址校验
        checkAddress() {
            return new Promise((resolve, reject) => {
                if(this.checked) {
                    resolve(true)
                } else {
                    const ref = this.$refs.CardAddress;
                    const refSubmit = ref.$refs.AddressSubmit;
                    refSubmit.getRef()
                    refSubmit.checkForm().then(res => {
                        resolve(res)
                    })
                }
            })
        },
        setEmbedData(obj) { // 设置账单数据
            if(this.checked) return
            const ref = this.$refs.CardAddress;
            ref.setEmbedData({
                order_id: obj.id,
                order_seq: obj.originOrderId
            })
        },
        setBillAddress() { // 设置账单地址
            if(this.checked) return
            const ref = this.$refs.CardAddress;
            const refSubmit = ref.$refs.AddressSubmit;
            refSubmit.handleSubmit()
        },
    },
}
