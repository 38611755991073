
import OrderCouponList from './OrderCouponList'

export default {
    name: 'OrderCoupon',
    components: {
        OrderCouponList
    },
    asyncData({api, cookies, ...context}) {},
    props: {
        query: Object,
        // hasLogin: Boolean,
        skeleton: Boolean,
        currency: Object,
        middleEmit: Object,
        orderInfo: Object,
        params: Object
    },
    data() {
        return {
            hasLogin: false,
            rsInfo: {},
            coupon: {
                name: '',
                getTip: '',
                info: {},
                params: {},
                hasApply: true,
                hasExchange: false,
            },
            couponData: {},
            popup: {
                visible: false
            },
            instanceCompo: {
                orderCouponList: ''
            }
        }
    },
    computed: {},
    watch: {
        orderInfo: {
            handler() {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {
    },
    mounted() {
        this.initialSet()
        this.setCouponData()
    },
    methods: {
        setData() {
            this.rsInfo = JSON.parse(JSON.stringify(this.orderInfo));
        },
        // 优惠券
        getAutoCouponAjax() { // 优惠券-自动兑换
            return new Promise((resolve, reject) => {
                this.$api.coupon.getAutoCoupon({
                    data: JSON.stringify({
                        orderFrom: this.query.from,
                        spuIds: this.params.orderAmt.spuId,
                        orderItems: this.params.orderAmt.orderItems,
                    })
                }).then(response => {
                    const res = response?.result || {};
                    if(response.code == 'success') {
                        this.coupon.info = res
                        this.setCouponData()
                    } else {
                        this.removeCoupon()
                    }
                    resolve(res)
                })
            })
        },
        setExchangeCouponAjax() { // 优惠券-兑换
            return new Promise((resolve, reject) => {
                this.$api.coupon.getAcquireCoupon({
                    code: this.coupon.name,
                    time: new Date().getTime(),
                    spuIds: this.params.orderAmt.spuId,
                    orderItems: this.params.orderAmt.orderItems,
                }).then(response => {
                    const res = response?.result || {};
                    if(!res.status) {
                        this.$Toast(res.info)
                        this.removeCoupon()
                    }
                    if(response.code == 'success') {
                        this.coupon.info = res
                        this.setCouponData()
                        resolve(res)
                    }
                })
            })
        },
        setCouponData() { // 优惠券-数据处理
            const couponInfo = this.coupon.info;
            this.coupon.getTip = ''
            if(couponInfo.code) {
                this.coupon.params = {
                    couponId: couponInfo.id,
                    couponSpuIds: couponInfo.suitSpu,
                    couponAmount: couponInfo.percentAmount,
                    couponFreeShipping: couponInfo.freeShipping,
                    couponType: couponInfo.type,
                    freeShippingType: couponInfo.freeShippingType || 0
                }
                this.coupon.name = couponInfo.code
                // 优惠券提示
                const freeCouponTip = [`You've got a FREE SHIPPING`, `You've got a FREE STANDARD SHIPPING`, `You've got a FREE EXPRESS SHIPPING`];
                if(this.coupon.info.type == 3) {
                    this.coupon.getTip = freeCouponTip[this.coupon.info.freeShippingType || 0]
                }
            } else {
                this.coupon.params = {}
            }
            this.coupon.hasApply = !this.coupon.name
            this.coupon.hasExchange = false
        },
        getCoupon() {
            return new Promise((resolve, reject) => {
                const couponInfo = this.coupon.info;
                if(couponInfo.code) {
                    this.setExchangeCouponAjax().then(res => {
                        this.setStorageCoupon()
                        resolve(this.coupon)
                    })
                } else {
                    if(this.hasLogin) {
                        this.getAutoCouponAjax().then(res => {
                            this.setStorageCoupon()
                            resolve(this.coupon)
                        })
                    } else {
                        resolve(this.coupon)
                    }
                }
            })
        },
        // code change
        inputCode() {
            const couponInfo = this.coupon.info;
            if(!this.coupon.name) {
                this.coupon.hasApply = true
            } else {
                this.coupon.hasApply = !(this.coupon.name == couponInfo.code)
            }
            this.coupon.hasExchange = this.coupon.hasApply
        },
        // 兑换按钮
        handleCode() {
            if(!this.coupon.name){
                this.$Toast(this.$translate('Please Enter Promo Code'))
                return
            }
            if(this.coupon.hasApply) {
                this.setExchangeCoupon()
            } else {
                this.removeCoupon()
            }
        },
        setExchangeCoupon() { // 兑换
            this.setExchangeCouponAjax().then(res => {
                const ref = this.$refs.OrderCouponList;
                this.setStorageCoupon()
                ref && ref.updateCoupon()
                this.middleEmit.fn('handleCode', this.coupon)
                this.middleEmit.fn('getOrderInfo')
                this.popup.visible = false
            })
        },
        removeCoupon() { // 移除
            const ref = this.$refs.OrderCouponList;
            this.coupon.name = ''
            this.coupon.info = {}
            this.setCouponData()
            this.setStorageCoupon()
            ref && ref.updateCoupon()
            // this.popup.visible = false
            this.middleEmit.fn('handleCode', this.coupon)
            this.middleEmit.fn('getOrderInfo')
        },
        setStorageCoupon() { // 优惠券缓存
            if(this.coupon.info.code) {
                this.$storage.set('order/coupon', this.coupon.info)
            } else {
                this.$storage.remove('order/coupon')
            }
        },
        // 优惠券选择
        handleSet() { // 优惠券选择-显示
            const ref = this.$refs.OrderCouponList;
            this.popup.visible = true
            if(ref && this.hasLogin) {
                ref.updateCoupon()
            }
            // this.$router.push({
            //     name: 'CouponChoose'
            // })
            // this.$storage.set('page/refresh', true)
        },
        handleCoupon(obj) { // 优惠券选择-select
            if(this.coupon.name != obj.code) {
                this.coupon.name = obj.code
                this.coupon.hasApply = true
                this.popup.visible = false
                this.handleCode()
            }
        },
        // 获取账户优惠券数据
        getCouponList(obj) {
            this.couponData = obj || {}
        },
        // 初始化
        initialSet() {
            const ref = this.$refs.OrderCouponList;
            const coupon = this.$storage.get('order/coupon') || {};
            this.coupon.info = coupon
            this.hasLogin = this.$login()
            this.instanceCompo.orderCouponList = ref
        }
    },
}
