import { render, staticRenderFns } from "./OrderSubmit.vue?vue&type=template&id=4896493c&scoped=true&"
import script from "./OrderSubmit.vue?vue&type=script&lang=js&"
export * from "./OrderSubmit.vue?vue&type=script&lang=js&"
import style0 from "./OrderSubmit.vue?vue&type=style&index=0&id=4896493c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4896493c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnImage: require('/home/php1/m-nuxt/components/YfnImage.vue').default,YfnButton: require('/home/php1/m-nuxt/components/YfnButton.vue').default,YfnFooter: require('/home/php1/m-nuxt/components/YfnFooter.vue').default})
