
import CardPay from '../../cardPay/index'

export default {
    name: 'OrderCard',
    components: {
        CardPay
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        // 信用卡校验
        checkCard() {
            return new Promise((resolve, reject) => {
                const ref = this.$refs.CardPay;
                ref.getFormData()
                ref.checkForm().then(res => {
                    resolve(res)
                })
            })
        },
        // 信用卡-提交
        setEmbedSubmit(obj) {
            const ref = this.$refs.CardPay;
            ref.setEmbedSubmit(obj)
        }
    },
}
