
export default {
    name: 'OrderProduct',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        currency: Object,
        placeProduct: Object,
    },
    data() {
        return {
            rsInfo: {
                quantity: 0,
                list: []
            },
            collapse: 1
        }
    },
    computed: {},
    watch: {
        placeProduct: {
            handler() {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            const rsInfo = JSON.parse(JSON.stringify(this.placeProduct)) || {list: []};
            let quantity = 0
            this.rsInfo.list = rsInfo.list;
            this.rsInfo.list.forEach(p => {
                // props
                let arr = [];
                if(p.simpleSpec) {
                    p.simpleSpec.forEach(c => {
                        arr.push(c.specVal)
                    })
                    p.rsPropsText = arr.join('/')
                }
                // 定制数据
                p.hasCustomize = p.customizeParams && p.customizeParams[0]
                p.rsFirstCustomize = {}
                if(p.hasCustomize) {
                    p.rsFirstCustomize = p.customizeParams[0];
                    p.customizeLength = p.customizeParams.length
                    p.hasCustomizeMore = p.customizeLength > 1
                }
                p.rsImg = p.rsFirstCustomize.url || p.skuPic
                // 商品数据
                p.rsProduct = {
                    ...p,
                    title: p.productName,
                    pic: p.rsImg,
                    salePrice: p.goodsType == 3 ? 0 : p.skuPrice,
                    orgPrice: p.skuOrgPrice,
                    displaySalePrice: p.goodsType == 3 ? '0.00' : p.displaySkuPrice,
                    displayOrgPrice: p.displaySkuOrgPrice
                }
                p.rsOptions = {
                    hasCustomize: false,
                    currency: this.currency
                }
                quantity += p.quantity
            })
            this.rsInfo.quantity = quantity
        },
        handlePreview(obj) {
            this.$ImagePreview([obj.inputImage])
        }
    },
}
