
export default {
    name: 'OrderCheckRepeat',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        popup: Object,
        checkRepeat: Object,
        middleEmitChild: Object
    },
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        handleSubmit() {
            this.popup.visible = false
            this.middleEmitChild.fn('checkRepeatSuccess')
        },
        handleOrder() {
            this.popup.visible = false
            this.$router.push({
                name: 'OrderDetail',
                query: {
                    id: this.checkRepeat.orderId
                }
            })
        },
        closePopup() {
            this.middleEmitChild.fn('checkRepeatClose')
        }
    },
}
