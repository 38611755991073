
export default {
    name: 'OrderSettle',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        currency: Object,
        amt: Object,
        coupon: Object
    },
    data() {
        return {
            rsInfo: {},
            settle: {
                list: []
            }
        }
    },
    computed: {},
    watch: {
        amt: {
            handler() {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            this.rsInfo = JSON.parse(JSON.stringify(this.amt));
            const couponInfo = this.coupon.info || {};
            this.settle = {
                ...this.rsInfo,
                list: []
            }
            // Shipping Fee
            const shippingFee = this.$price(this.settle.displayShippingFee, this.currency.symbol);
            const shippingClass = this.settle.shippingFee && couponInfo.type != 3 ? '' : 'color-green';
            let shippingSubtitle = '';
            let shippingSub = this.settle.shippingFee ? `${shippingFee}` :  this.$translate('Free');
            if(couponInfo.code && couponInfo.type == 3) {
                shippingSub = `${this.$translate('Free')}<span class="OrderSettle--line color-grey">${this.settle.shippingFee ? shippingFee : ''}</span>`
                shippingSubtitle = `<span class="OrderSettle-shipping-subtitle color-orange">(${this.$translate('Couponcode')}: ${couponInfo.code})</span>`
            }
            // 结算明细
            this.settle.list.push({
                title:  this.$translate('Retail Price'),
                sub: `${this.$price(this.settle.displayRetailPrice, this.currency.symbol)}`,
                class: 'OrderSettle-item--line',
                visible: true
            }, {
                title:  this.$translate('Subtotal'),
                sub: `${this.$price(this.settle.displaySubTotal, this.currency.symbol)}`,
                visible: true
            }, {
                title:  this.$translate('Shipping Fee'),
                sub: shippingSub,
                subtitle: shippingSubtitle,
                class: shippingClass,
                visible: true
            }, {
                title: `${ this.$translate('Coupon Discount')}<span>(${couponInfo.code})</span>`,
                sub: `-${this.$price(couponInfo.displayPercentAmount, this.currency.symbol)}`,
                class: 'OrderSettle-item--red',
                visible: couponInfo.code && couponInfo.type != 3
            }, {
                title: `COD ${ this.$translate('Fee')}`,
                sub: `${this.$price(this.settle.displayCodServiceFee, this.currency.symbol)}`,
                visible: this.settle.codServiceFee
            }, {
                title: `${ this.$translate('Online Payment Discount')}`,
                sub: `-${this.$price(this.settle.displayOnlinePayDiscount, this.currency.symbol)}`,
                class: 'OrderSettle-item--red',
                visible: this.settle.onlinePayDiscount
            }, {
                title: `${ this.$translate('Use Wallet')}`,
                sub: `-${this.$price(this.settle.displayWalletAmount, this.currency.symbol)}`,
                class: 'OrderSettle-item--red',
                visible: this.settle.walletAmount
            }, {
                title: `${ this.$translate('Gift Card Balance')}`,
                sub: `-${this.$price(this.settle.displayGiftCardAmount, this.currency.symbol)}`,
                class: 'OrderSettle-item--red',
                visible: this.settle.giftCardAmount
            })
        },
    },
}
