
export default {
    name: 'OrderWallet',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        currency: Object,
        middleEmit: Object,
        amt: Object,
    },
    data() {
        return {
            rsAmt: {},
            wallet: {
                disabled: false,
                useWalletAmt: '',
                displayWalletAmount: '0.00',
                btnDisabled: false,
                initialUseWalletAmt: ''
            },
            popup: {
                visible: false
            }
        }
    },
    computed: {},
    watch: {
        amt: {
            handler(n, o) {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 数据设置
        setData() {
            this.rsAmt = JSON.parse(JSON.stringify(this.amt))
            this.wallet.disabled = !this.rsAmt.maxUseWallet
            this.wallet.displayWalletAmount = this.rsAmt.displayWalletAmount
            this.wallet.useWalletAmt = this.rsAmt.walletRevealAmt ? this.rsAmt.displayWalletRevealAmt : ''
            this.wallet.btnDisabled = this.wallet.disabled || this.wallet.useWalletAmt === ''
            this.wallet.maxUseWallet = this.rsAmt.maxUseWallet
            this.wallet.walletAmount = this.rsAmt.walletAmount
            this.wallet.userWalletTotalAmt = this.rsAmt.userWalletTotalAmt
            this.wallet.displayMaxUseWallet = this.rsAmt.displayMaxUseWallet
            this.wallet.displayWalletAmount = this.rsAmt.displayWalletAmount
            this.wallet.displayUserWalletTotalAmt = this.rsAmt.displayUserWalletTotalAmt
            this.wallet.initialUseWalletAmt = this.wallet.useWalletAmt
            this.middleEmit.fn('changeWalletData', this.wallet)
        },
        // 点击
        handleWallet() {
            this.popup.visible = true
        },
        // apply
        handleApply() {
            this.popup.visible = false
            this.wallet.useWalletAmt = this.wallet.initialUseWalletAmt
            this.middleEmit.fn('changeWalletData', this.wallet)
            this.middleEmit.fn('getOrderInfo')
        },
    },
}
