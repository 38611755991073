
export default {
    name: 'OrderNote',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object
    },
    data() {
        return {
            note: {
                remarks: ''
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        inputNote() {
            this.$storage.set('order/remarks', this.note.remarks)
            this.middleEmit.fn('inputNote', this.note)
        },
        initialSet() {
            this.note.remarks = this.$storage.get('order/remarks') || ''
        }
    },
}
