
export default {
    name: 'OrderShipping',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        query: Object,
        currency: Object,
        middleEmit: Object,
        orderInfo: Object,
        biz: Object
    },
    data() {
        return {
            rsInfo: {},
            shipping: {
                method: '',
                list: []
            }
        }
    },
    computed: {},
    watch: {
        orderInfo: {
            handler() {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            this.rsInfo = JSON.parse(JSON.stringify(this.orderInfo));
            this.shipping.method = this.$storage.get('order/shippingMethod')
            this.shipping.list = this.rsInfo.shipping || []
            // 延迟发货物流处理
            if(this.biz.standby && !this.rsInfo.delayDelivery) {
                this.rsInfo.delayDelivery = this.biz.standby
            }
            if(this.rsInfo.delayDelivery) {
                if(this.shipping.list.length > 1) {
                    this.shipping.list.splice(1, 1)
                }
            }
            // 运输数据
            let shippingMethod = '';
            let shippingObj = {};
            this.shipping.list.forEach(p => {
                // showType 1 -正常展示 2-置灰 3-不展示
                p.displayAmt = 0
                if(!p.fee) {
                    p.displayAmt = this.$translate('Free')
                } else {
                    p.displayAmt = `${this.$price(p.displayFee, this.currency.symbol)}`
                }
                if(p.displayOriginalFee && p.displayOriginalFee > p.fee) {
                    p.displayOriginalAmt = `${this.$price(p.displayOriginalFee, this.currency.symbol)}`
                }
                p.dataStr = `${p.minDayStr}-${p.maxDayStr}`
                p.disabled = p.showType != 1
                if(p.selected) {
                    shippingMethod = p.name
                    shippingObj = p
                }
            })
            const canShipping = this.shipping.list.filter(p => {
                return !p.disabled
            });
            // 运输方式
            if(!shippingMethod) {
                const firstShipping = canShipping[0] || {};
                shippingMethod = firstShipping.name
                shippingObj = firstShipping
            }
            this.shipping.method = shippingMethod
            this.setStorageShipping()
            this.middleEmit.fn('handleShipping', {
                ...shippingObj,
                ...this.shipping
            })
        },
        handleShipping(obj) {
            if(obj.disabled && obj.toastInfo) {
                this.$Toast(obj.toastInfo)
                return
            }
            this.shipping.method = obj.name
            this.middleEmit.fn('handleShipping', {
                ...obj,
                ...this.shipping,
            })
            this.$fnApi.debounce(() => {
                this.setStorageShipping()
                this.middleEmit.fn('getOrderInfo')
            }, 200)
        },
        setStorageShipping() {
            this.$storage.set('order/shippingMethod', this.shipping.method)
        }
    },
}
